<template>
  <div class="home">
    <!-- <i class="star"></i>
    <i class="star star-p2"></i> -->
    <div class="input-box">
      <input type="text" class="form input" v-model="queryString" />
      <button class="form submit" @click="query"></button>
    </div>
    <p class="tips">非严肃测试·可不要当真噢</p>
    <div class="pop" v-if="tips">
      <span>{{ tips }}</span>
    </div>
  </div>
</template>

<script>
import { queryWord } from "@/request/api.js";
export default {
  name: "Index",
  data() {
    return {
      //测试文字
      queryString: "",
      //提示
      tips: "",
    };
  },
  methods: {
    /**
     * 测试方法
     * 200:正常，400:服务器出错，900:敏感词
     */
    query() {
      if (this.queryString) {
        queryWord({ word: this.queryString }).then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$router.push({
              path: "/result",
              query: { t: res.data, n: this.queryString }, //t=type,n=name
            });
          } else if (res.code == 400) {
            this.tips = "出小差了，再试试看";
            setTimeout(() => {
              this.tips = "";
            }, 2000);
          } else if (res.code == 900) {
            this.tips = "不要输入敏感词语哦";
            setTimeout(() => {
              this.tips = "";
            }, 2000);
          }
        });
      } else {
        this.tips = "你还没有输入名字哦";
        setTimeout(() => {
          this.tips = "";
        }, 2000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes star {
  0% {
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    opacity: 0.7;
    -webkit-opacity: 0.7;
  }
  100% {
    transform: scale(30, 30);
    -webkit-transform: scale(30, 30);
    opacity: 0;
    -webkit-opacity: 0;
  }
}
@-webkit-keyframes star {
  0% {
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    opacity: 0.7;
    -webkit-opacity: 0.7;
  }
  100% {
    transform: scale(30, 30);
    -webkit-transform: scale(30, 30);
    opacity: 0;
    -webkit-opacity: 0;
  }
}
.home {
  background-image: url("../assets/images/query_bg.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center 0;
  width: 100vw;
  height: 100vh;
  min-height: 1500px;
  background-color: #060606;
  position: relative;
  .star {
    width: 3px;
    height: 3px;
    position: absolute;
    top: 202px;
    right: 345px;
    border-radius: 100px;
    animation: star 1.5s infinite;
    -webkit-animation: star 1.5s infinite;
    background: #fdfdfb;
    box-shadow: inset 0 0 1px #c2892d;
  }
  .star-p2 {
    right: 110px;
    top: 235px;
  }
  .input-box {
    background-image: url("../assets/images/input.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    width: 451px;
    height: 334px;
    position: absolute;
    top: 750px;
    left: 50%;
    margin-left: -225px;
    padding-top: 130px;
    .form {
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center center;
      border-radius: 30px;
      border-color: transparent;
      display: block;
      margin: 0 auto;
      background-color: transparent;
      &.input {
        background-image: url("../assets/images/test_input.png");
        width: 302px;
        height: 60px;
        font-size: 32px;
        padding: 0 20px;
        text-align: center;
      }
      &.submit {
        background-image: url("../assets/images/test_button.png");
        width: 301px;
        height: 60px;
        margin-top: 13px;
      }
    }
  }
  .tips {
    font-size: 28px;
    color: #6a432a;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 80px;
    width: 100%;
    letter-spacing: 18px;
  }
  .pop {
    position: fixed;
    top: 40%;
    left: 50%;
    width: 500px;
    margin-left: -250px;
    display: flex;
    justify-content: center;
    span {
      background: rgba(0, 0, 0, 0.6);
      color: #fff;
      text-align: center;
      padding: 20px;
      border-radius: 70px;
      font-size: 26px;
    }
  }
}
.save-img {
  font-size: 50px;
}
img {
  width: 100%;
  display: block;
}
</style>
